import { requestJson, useApiGet } from '@papertrail/web3-utils'
import { mapUser, mapAccount, mapMember, mapPartner } from '@papertrail/types'

export const loadPartner = async () => {
  const result = await requestJson({ path: '/partners/domain', method: 'GET' })
  return mapPartner(result.data)
}

export const loadUser = async () => {
  const result = await requestJson({ path: '/me', method: 'GET' })
  return mapUser(result.data)
}

export const loadAccount = async (accountId: string) => {
  const result = await requestJson({
    path: `/accounts/${accountId}`,
    method: 'GET',
    query: { include: 'subscription,subscription.package,flags,folder,task_states' }
  })
  return mapAccount(result.data)
}

export const loadMember = async (accountId: string) => {
  const result = await requestJson({
    path: `/accounts/${accountId}/me`,
    method: 'GET'
  })
  return mapMember(result.data)
}

export function useApiGetMember(accountId: string) {
  return useApiGet(`/accounts/${accountId}/me`, (data) => data.data)
}

export function useApiGetCurrentUser() {
  return useApiGet(`/me`, (data) => data.data)
}
