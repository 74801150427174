import { useEffect, useState } from 'react'
import { PapertrailSession } from './session'
import { EventManager } from '@papertrail/web3-utils'
import { User, Member, Account } from '@papertrail/types'

/**
 * Hooks for accessing session objects so that react will rerender if they are updated.
 */

export function useSessionUser(): User {
  const [user, setUser] = useState(PapertrailSession.getUser())

  useEffect(() => {
    const listenerRef = EventManager.subscribe(['papertrail:user_updated'], (data: any) => {
      setUser(data)
    })
    return () => {
      //unsubscribe when unmount
      EventManager.unsubscribe(listenerRef)
    }
  }, [])

  return user
}

export function useSessionAccount(): Account {
  const [account, setAccount] = useState(PapertrailSession.getAccount())

  useEffect(() => {
    const listenerRef = EventManager.subscribe(['papertrail:account_updated'], (data: any) => {
      setAccount(data)
    })
    return () => {
      //unsubscribe when unmount
      EventManager.unsubscribe(listenerRef)
    }
  }, [])

  return account
}

export function useSessionMember(): Member {
  const [member, setMember] = useState(PapertrailSession.getMember())

  useEffect(() => {
    const listenerRef = EventManager.subscribe(['papertrail:member_updated'], (data: any) => {
      setMember(data)
    })
    return () => {
      //unsubscribe when unmount
      EventManager.unsubscribe(listenerRef)
    }
  }, [])

  return member
}
