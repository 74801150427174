import Pusher from 'pusher-js'
import { EventManager } from '@papertrail/web3-utils'

export default class PusherManager {
  private apiKey: string
  private debug: boolean
  private connection: any

  constructor(apiKey: string, debug: boolean) {
    this.apiKey = apiKey
    this.debug = debug
    this.connection = null
  }

  connect() {
    if (Pusher) {
      this.connection = {}
      this.connection.client = this.createClient()
    }
  }

  disconnect() {
    if (this.connection && this.connection.client && this.connection.client.disconnect) {
      this.connection.client.disconnect()
    }
    this.connection = null
  }

  createClient() {
    return new Pusher(this.apiKey, {
      cluster: 'mt1',
      disableStats: true,
      authEndpoint: '/api/auth/pusher',
      auth: {
        params: {},
        headers: {
          Accept: 'application/vnd.papertrail.api+json; version=1.0'
        }
      }
    })
  }

  accountChannel = function (action, accountId) {
    if (Pusher && accountId) {
      if (!this.connection || !this.connection.client) {
        this.connect()
        this.accountChannel(action, accountId)
      } else {
        if (action === 'subscribe') {
          this.connection.client.subscribe('presence-account-' + accountId)
        } else {
          this.connection.client.unsubscribe('presence-account-' + accountId)
        }
      }
    }
  }

  userChannel(action, userId) {
    if (Pusher && userId) {
      if (!this.connection || !this.connection.client) {
        this.connect()
        this.userChannel(action, userId)
      } else {
        if (action === 'subscribe') {
          this.connection.client.subscribe('presence-user-' + userId)
          const privateUserChannel = this.connection.client.subscribe('private-user-' + userId)

          privateUserChannel.bind_global((eventName, data) => {
            if (this.debug) {
              // console.log('Pusher event - ' + eventName, data)
            }
            EventManager.broadcast(eventName, data)
          })
        } else {
          this.connection.client.unsubscribe('presence-user-' + userId)
          this.connection.client.unsubscribe('private-user-' + userId)
        }
      }
    }
  }
}
