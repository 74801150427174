import { User } from '@papertrail/types'

export default class Intercom {
  private booted: boolean
  private intercomAppId: string

  isBooted() {
    return this.booted
  }

  boot(intercomAppId: string) {
    this.intercomAppId = intercomAppId
    if (window.Intercom) {
      window.Intercom('boot', { app_id: intercomAppId })
      this.booted = true
    }
  }

  /** This is called on route change, so intercom shows recent page views */
  ping() {
    window.Intercom('update')
  }

  /* Tell intercom about the current user */
  setUser(user: User) {
    if (this.booted && user) {
      const intercomSettings: any = {
        user_id: user.id,
        user_hash: user.intercomHash
      }

      window.Intercom('update', intercomSettings)
    }
  }

  /* Tell intercom which account is being accessed */
  setAccountId(accountId) {
    if (this.booted) {
      const intercomSettings: any = {
        company: {}
      }
      if (accountId) {
        intercomSettings.company.id = accountId
      }
      window.Intercom('update', intercomSettings)
    }
  }

  /* essential so that private messages are not available in messenger when user logs out */
  logout() {
    if (window.Intercom) {
      window.Intercom('shutdown')
      window.Intercom('boot', { app_id: this.intercomAppId })
    }
  }

  /* Open the intercom messenger withy the supplied message */
  message(text: string) {
    if (window.Intercom) {
      window.Intercom('showNewMessage', text)
    }
  }

  trackEvent(eventName, data) {
    if (window.Intercom) {
      window.Intercom('trackEvent', eventName, data)
    }
  }
}
